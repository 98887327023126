import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@mui/material/Select";
import { Stack, Box, MenuItem, FormControl, InputLabel } from '@mui/material';
import 'styles/_tracking.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'styles/_font.scss';
import 'styles/_progress-tracker.scss';
import { getTrackingDetails, getTrackingServiceMaintenanceAlert, getMessageToShow, getUTCTime } from 'actions/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import InnovelServiceAlert from 'containers/common/innovel_service_alert.js'
import { MOBILE_WIDTH } from 'constants.js';
import parse from 'html-react-parser';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import LogoffLayer from './logoffLayer';
import InnovelFlashMessage from 'containers/common/innovel_flash_message.js';
import { RegexMatch } from 'containers/common/innovel_util';
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import AddressFormatter from '../containers/common/AddressFormater';
import moment from 'moment';

const iconStyles = {
  selectIcon: {
    color: "#3071A9"
  }
};
const CustomExpandMore = withStyles(iconStyles)(
  ({ className, classes, ...rest }) => {
    return (
      <ExpandMoreIcon
        {...rest}
        fontSize='large'
        className={clsx(className, classes.selectIcon)}
      />
    );
  }
);

class newTracking extends Component {
  constructor() {
    super();
    this.topReference = React.createRef();
    this.state = {
      loading: false,
      tabledata: '',
      appointment: '',
      stat3: '',
      ordNo: '',
      address: '',
      deliveryStatus: '',
      shippedDate: '',
      statusCode: '',
      deliveryPreparationMessage: '',
      timeWindowMessage: '',
      inputValue: '',
      trackingNumb: '',
      showSteps: false,
      showTrackingResult: false,
      showClickButton: false,
      showNoRecordsMessage: false,
      schedulerLink: '',
      deliveryMockStatus: '',
      del: false,
      mdr: false,
      xds: false,
      xdu: false,
      reg: false,
      disableSubmission: false,
      isMobileDevice: window.innerWidth <= MOBILE_WIDTH,
      message: '',
      timeout: false,
      callBlocked: false,
      return_flag: '',
      validNumber: true,
      trackingList: [],
      trackingData: [],
      trackingNumActive: '',
      //showSchedulerLink: false,
      clientCode: '',
      spinner: false,
      trackingNewVar: process.env.REACT_APP_TRACKING_NEW_VARIABLES
    };
  }

  componentDidMount() {
    let url = document.URL;
    var urlTimeout = url.split('?');
    if (urlTimeout.length > 1 && urlTimeout[1] !== '') {
      var param = urlTimeout[1].split('=');

      if (param[0] === "timeout") {
        let messageObj = {
          alertType: 'success',
          message: 'You have been brought to the start of the tracking page due to inactivity.'
        };
        this.setState({ timeout: true });
        this.props.getMessageToShow(messageObj);
      }
    }
    this.topReference.current.scrollIntoView();
    let urlUppercase = url.toUpperCase();
    let trackingIndex = urlUppercase.indexOf('ID=');
    document.title = "Track My Delivery | Costco Logistics"

    this.props
      .getTrackingServiceMaintenanceAlert()
      .then(result => {
        let maintenanceActionDisable = (
          result?.payload?.data?.serviceResponse?.alertMessageResponse?.messages['tracking.alert.action.disable'] === "true" &&
          (result?.payload?.data?.serviceResponse?.alertMessageResponse?.messages['tracking.maintenance.message'] !== ""))
          ? true : false;
        if (maintenanceActionDisable === "true" || maintenanceActionDisable === true) {
          this.setState({ disableSubmission: true });
          if (trackingIndex !== -1) {
            this.setState({ spinner: true });
            window.location.replace("/");
          }
        } else {
          this.setState({ disableSubmission: false });
          if (trackingIndex !== -1) {
            //const urlValue = window.location.search;
            const params = new URLSearchParams(url.split('?')[1]);
            let trackNum = params.get('id') || params.get('Id') || params.get('ID') || params.get('iD');
            this.setState({ searching: true });
            this.props.getUTCTime().then(result => {
              const httpStatus = result.payload.status
              if (httpStatus === 200) {
                //var response = result.payload.data;
                sessionStorage.setItem("utcTime", result.payload.request.response)
              }
              else {
                sessionStorage.removeItem("utcTime");
              }
              this.setState(
                {
                  loading: true,
                  trackingNumb: trackNum,
                  inputValue: trackNum,
                  showSteps: false,
                  showTrackingResult: false,
                  showClickButton: false,
                  showNoRecordsMessage: false,
                  tabledata: '',
                  ordNo: '',
                  address: '',
                  deliveryStatus: '',
                  shippedDate: '',
                  statusCode: '',
                  deliveryPreparationMessage: '',
                  timeWindowMessage: '',
                  schedulerLink: '',
                  deliveryMockStatus: '',
                  del: false,
                  mdr: false,
                  xds: false,
                  xdu: false,
                  reg: false,
                  trackingList: [],
                  trackingData: [],
                  trackingNumActive: trackNum
                },
                this.CheckStatus
              );
            });
          }
        }
      });

    // Google Analytics event
    window.gtag('config', process.env.REACT_APP_GTAG_ID, {
      'page_title': 'Track My Delivery',
      'page_path': '/tracking'
    });
    window.addEventListener('resize', this.updateMobileDevice);
  }

  handleSubmitButtonClick = () => {
    this.setState({ searching: true });
    this.props.getUTCTime().then(result => {
      const httpStatus = result.payload.status
      if (httpStatus === 200) {
        //var response = result.payload.data;
        sessionStorage.setItem("utcTime", result.payload.request.response)
      }
      else {
        sessionStorage.removeItem("utcTime");
      }
      this.setState(
        {
          loading: true,
          trackingNumb: this.state.inputValue,
          orderNum: '',
          showNoRecordsMessage: false,
          showSteps: false,
          showTrackingResult: false,
          showClickButton: false,
          tabledata: '',
          ordNo: '',
          address: '',
          deliveryStatus: '',
          shippedDate: '',
          statusCode: '',
          deliveryPreparationMessage: '',
          timeWindowMessage: '',
          schedulerLink: '',
          deliveryMockStatus: '',
          del: false,
          mdr: false,
          xds: false,
          xdu: false,
          reg: false,
          callBlocked: false,
          trackingList: [],
          trackingData: [],
          trackingNumActive: this.state.inputValue.toUpperCase()
        },
        this.CheckStatus
      );
    });
  };

  handleTrackingInputChange = event => {
    this.props.history.replace("tracking");
    const value = event.target.value.trim();
    this.setState({
      inputValue: value,
      showNoRecordsMessage: false
    });
    if (value && RegexMatch(value, 1)) {
      this.setState({ validNumber: true, inputValue: value, message: '', errorMsg: '', timeout: false, callBlocked: false });
    } else {
      this.handleErrorFieldState();
    }
  };

  handleErrorFieldState = () => {
    this.setState({ validNumber: false, errorMsg: 'Enter a valid tracking or order number.', showErrorMessage: false });
  };

  CheckStatus = () => {
    this.setState({ loading: true });
    this.props.getTrackingDetails(this.state.trackingNumb.toUpperCase()).then(result => {
      if (result.payload.status === 200) {
        var response = result.payload.data;
        if (
          response != null &&
          response.serviceResponse != null &&
          response.serviceResponse.trackingDetails[0].orderDetailList != null
        ) {
          //Set the tracking number array for the drop down list
          response.serviceResponse.trackingNumbers.map((track) => {
            this.state.trackingList.push(track);
          })

          response.serviceResponse.trackingDetails.map((details) => {
            this.state.trackingData.push(details);
          })

          this.setState({ clientCode: response.serviceResponse.client_code });
          this.setState({ orderNum: response.serviceResponse.trackingDetails[0].orderNumber });
          if (this.state.clientCode !== undefined && this.state.clientCode != null) {
            if (this.state.clientCode === '000014') {
              this.setState({
                assistanceText: 'If you need assistance, visit '
              });
            }
            else {
              this.setState({
                assistanceText: ''
              });
            }
          }

          let statCode = response.serviceResponse.trackingDetails[0].statCode;
          switch (statCode) {
            case 'REG': /* Order Processed */
              this.setState({ reg: true });
              break;
            case 'XDU': //CrossDock Received
              this.setState({ reg: true, xdu: true });
              break;
            case 'XDS': /*CrossDock Shipped*/
              this.setState({ reg: true, xdu: true, xds: true });
              break;
            case 'MDR': /*DeliveryDepot Received*/
              this.setState({ reg: true, xdu: true, xds: true, mdr: true });
              break;
            case 'DEL': /* Shipment Delivered */
              this.setState({
                reg: true,
                xdu: true,
                xds: true,
                mdr: true,
                del: true
              });
              break;
            default:
              console.log('Ideally should never come here');
          }
          this.TrackingData(this.state.trackingData[0].trackingNumber);


          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            this.setState({ showSteps: false });
          } else {
            this.setState({ showSteps: true });
          }

          this.setState({ showTrackingResult: true });
        } else {
          this.setState({
            showNoRecordsMessage: true
          });
        }
      } else {
        if (result.error && result.payload.request.status === 0) {
          let messageObj = {
            alertType: 'error',
            message: 'The use of pop-up or ad blockers may prevent some features of our website from working as intended.  If you have a blocker installed, try turning it off in your browser settings and refresh the page.'
          };
          this.setState({ callBlocked: true });
          this.props.getMessageToShow(messageObj);
        }
        else {
          this.setState({
            showNoRecordsMessage: true
          });
        }
      }
      this.setState({ loading: false, searching: false });
      if (this.state.showNoRecordsMessage) {
        window.location.hash = '#norecords'
      }
    });
  };

  TrackingData = (tracking) => {
    var linkActive = false;
    this.state.trackingData.map(trackingDetails => {
      if (trackingDetails.trackingNumber == tracking) {
        linkActive = trackingDetails.showLink;
        this.setState({
          orderNum: trackingDetails.orderNumber,
          address: trackingDetails.address,
          deliveryStatus: trackingDetails.deliveryStatus,
          shippedDate: trackingDetails.shipDate,
          statusCode: trackingDetails.statCode,
          deliveryPreparationMessage: trackingDetails.deliveryPreparationMessage,
          timeWindowMessage: trackingDetails.timeWindowMessage,
          tabledata: trackingDetails.orderDetailList,
          targetWindowFrom: trackingDetails.targetWindowFrom ? moment(trackingDetails.targetWindowFrom?.trim(),  ["h:mm A"]).format("h:mm A") : "",
          targetWindowTo: trackingDetails.targetWindowTo ?moment(trackingDetails.targetWindowTo?.trim(),  ["h:mm A"]).format("h:mm A") : "",
          appointment: trackingDetails.appointment,
          stat3: trackingDetails.stat3,
          return_flag: trackingDetails.return_flag,
          trackingNumActive: trackingDetails.trackingNumber,
          //showSchedulerLink: trackingDetails.showLink
        });
        if (this.state.trackingNewVar == 'true') {
          this.setState({deliveryMockStatus: trackingDetails.mainStatus})
        } else {
          this.setState({deliveryMockStatus: trackingDetails.orderDetailList[0].orderList[0].status})
        }
      }
    })

    this.schedulerLinkToggle(tracking, linkActive);
  };

  schedulerLinkToggle = (tracking, linkActive) => {
    if (linkActive === true) {
      let scheduler = '';
      let env = process.env.REACT_APP_RFT_ENV || 'local';

      if (env === "local") {
        scheduler = 'http://localhost:3000/userselfschedule';
      } else {
        scheduler = process.env.REACT_APP_SCHEDULER_LINK;
      }
      localStorage.setItem("source", "tracking");
      scheduler = scheduler + '?order=' + tracking;

      this.setState({
        schedulerLink: scheduler,
        showClickButton: true
      });
    } else {
      this.setState({ showClickButton: false });
    }
  };


  selectTracking = (e) => {
    this.setState({ trackingNumActive: e.target.value });
    this.TrackingData(e.target.value);
  }

  ValidateTimeWindow = (date, startTime, endTime) => {
    var scheduledStartTime = moment(date + " " + startTime, ["YYYY-MM-DD HH:mm"]);
    var scheduledEndTime = moment(date + " " + endTime, ["YYYY-MM-DD HH:mm"]);

    var fullDayTW = moment(scheduledEndTime).diff(scheduledStartTime, 'hours') > process.env.REACT_APP_TIME_WINDOW_RANGE ? true : false;

    return fullDayTW;
  }


  render() {
    const messObj = this.props.messageObj;
    const timeStamp = this.state.targetWindowFrom && this.state.targetWindowTo ? this.state.targetWindowFrom + " to " + this.state.targetWindowTo : "";
    const fullDayTW = this.state.targetWindowFrom && this.state.targetWindowTo ? this.ValidateTimeWindow(moment().format("DD-MM-YYYY"), this.state.targetWindowFrom, this.state.targetWindowTo) : true;
    return (
      <div className="container-fluid main-grid" role="main" ref={this.topReference}> 
        {this.state.spinner === true ?
          <TrackingSpinner /> : (
            <div>
              <div id="divTrackOrders" className="row">

                <div id="mains" className="sr-only" tabIndex="-1"> div</div>
                <div id="banner" className="col-lg-10 offset-md-1 offset-lg-2">

                  <h1 className="t1">
                    Track My Delivery
                  </h1>
                  <p className="body-copy">Enter your tracking number or Costco.com order number to track the status of your delivery.</p>
                  <InnovelServiceAlert messObj={messObj} name='tracking' />
                  {(this.state.timeout || this.state.callBlocked) && (
                    <div><p><InnovelFlashMessage /></p></div>
                  )}

                  {' '}
                </div>
                <div className="col-md-5 col-lg-4 offset-md-1 offset-lg-2 traking-ele">
                  {this.state.showNoRecordsMessage && (
                    <div id="norecords" role="alert" tabIndex="-1" className="err-msg">
                      <p align="center" className="">
                        The order or tracking number entered was not received by Costco Logistics. Check the number and try again or visit
                        <a target='_blank' rel='noopener noreferrer' href='https://www.costco.com/OrderStatusCmd'> My Orders</a> for more information.
                      </p>
                    </div>
                  )}
                  <TextField
                    error={!this.state.validNumber}
                    id="tracking-number"
                    label="Tracking or Order Number"
                    value={this.state.inputValue}
                    variant="outlined"
                    disabled={this.state.disableSubmission}
                    ref={this.trackInput}
                    onChange={this.handleTrackingInputChange}
                    helperText={this.state.errorMsg}
                    autoComplete="off"
                  />

                  <Button
                    disableRipple
                    variant="contained"
                    color="primary"
                    disabled={this.state.disableSubmission}
                    onClick={this.state.inputValue != "" ? this.state.validNumber && !this.state.searching ? this.handleSubmitButtonClick : null : this.handleErrorFieldState}>
                    Track My Delivery
                  </Button>
                  {this.state.loading && (
                    <TrackingSpinner />
                  )}

                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2 result-ele">
                  {this.state.showTrackingResult && (
                    <div>
                      <LogoffLayer />
                      {/*<hr className="separator" />*/}
                      <div style={{ marginTop: '40px' }}>
                        <Stack
                          direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                          spacing={{ xs: 2, sm: 2, md: 4, lg: 4 }}
                          fullWidth={{ xs: 'true', sm: 'true' }}
                        >
                          <Box
                            sx={{ width: '100%' }}
                          >
                            {this.state.clientCode === "000014" && this.state.orderNum && <div style={{ borderColor: '1px solid #ccc' }}>
                              <h2 className="t4">
                                Order Number
                              </h2>
                              <h3 className="t5">
                                <a href='https://www.costco.com/OrderStatusCmd' target='_blank' aria-label={"Order Number: " + this.state.orderNum} style={{ lineHeight: '0px', marginTop: '5px' }}>{this.state.orderNum}</a>
                              </h3>
                            </div>}
                          </Box>
                          {this.state.trackingList.length > 1 && <FormControl fullWidth>
                            <InputLabel id="shipment-label">Shipment</InputLabel>
                            <Select
                              labelId="shipment-label"
                              id="shipment"
                              label="Shipment"
                              IconComponent={CustomExpandMore}
                              value={this.state.trackingNumActive}
                              onChange={this.selectTracking}
                            >
                              {this.state.trackingList.map((number, index) => {
                                return (<MenuItem value={number}>{index + 1} of {this.state.trackingList.length}</MenuItem>)
                              })}
                            </Select>
                          </FormControl>}
                        </Stack>
                      </div>
                      <div id="results" className="result-content">
                        {/* Conditionally showing tracking result header */}
                        {this.state.showTrackingResult && (
                          <div>
                            {this.state.statusCode !== 'DEL' ?
                              <h2 className="t4 mar-top-16">
                                {this.state.deliveryMockStatus}
                              </h2>
                              : ''
                            }
                            {this.state.statusCode !== 'DEL' ?
                              <div className="ord-num mar-top-16">
                                <h3 id="trackingresult" className="t5">
                                  Tracking Number{' '}
                                </h3>
                                <h4 className="t5" style={{ fontWeight: "normal" }}>
                                  {this.state.trackingNumActive}
                                </h4>
                              </div>
                              : ''
                            }
                          </div>
                        )}
                        {this.state.showTrackingResult && (
                          <div className={"delivery-date " + (this.state.statusCode === 'DEL' ? 'delivered' : '')}>
                            <p>
                              <Stack
                                spacing={0}
                                direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
                              >
                                <item>
                                  {this.state.stat3 === 'D' &&
                                  (this.state.trackingNewVar == 'true' ?
                                    <h3 className="t5 mar-top-12 txt-bold">
                                      {this.state.deliveryMockStatus}
                                    </h3>
                                    :
                                    <h3 className="t5 mar-top-12 txt-bold">
                                      Delivered
                                    </h3>)
                                  }
                                  {this.state.stat3 !== 'D' &&
                                    this.state.return_flag === 'Y' &&
                                    (<h3 className="t5 mar-top-12">
                                      Return Pickup Date
                                    </h3>)
                                  }
                                  {this.state.stat3 !== 'D' &&
                                    this.state.return_flag !== 'Y' &&
                                    (<h3 className="t5 mar-top-12">
                                      Delivery Date
                                    </h3>)
                                  }
                                </item>
                                <item>
                                    {this.state.statusCode === 'DEL' ?
                                      <div  className="delivered-details"><span>{this.state.shippedDate + " " + timeStamp}</span></div>
                                      :
                                      <div className="mar-top-12"><span className={"t6 " + (this.state.stat3 === 'C' ? "red-color" : (this.state.appointment === 'N' ? "no-color" : ""))}>{this.state.deliveryStatus && parse(this.state.deliveryStatus)}</span><span>{fullDayTW === false ? timeStamp : ""}</span></div>
                                    }
                                </item>
                              </Stack>
                            </p>
                          </div>
                        )}
                        {this.state.stat3 === 'D' ?
                          <div className="ord-num">
                            <h5 id="trackingresult" className="t5">
                              Tracking Number{' '}
                            </h5>
                            <h5 span className="t5">
                              {this.state.trackingNumActive}
                            </h5>
                          </div>
                          : ''
                        }
                        {this.state.showClickButton && (
                          <div id="clickbutton" align="left">
                            <a
                              id="scheduleLink"
                              className="body-copy"
                              href={this.state.schedulerLink}
                            >
                              Schedule My Delivery
                            </a>
                          </div>
                        )}
                        <div>
                          {this.state.showTrackingResult && (
                            <div>
                              {this.state.deliveryStatus && (<div align="center" className="delivery-status">
                              </div>)}
                              {this.state.timeWindowMessage && (
                                <p className="body-copy mar-top-16">
                                  {parse(this.state.timeWindowMessage)}
                                </p>
                              )}
                              {this.state.deliveryPreparationMessage && (
                                <p className="body-copy mar-top-16">
                                  {parse(this.state.deliveryPreparationMessage)}
                                </p>)}
                              {this.state.assistanceText && (
                                <p className="body-copy mar-top-16">
                                  {this.state.assistanceText}
                                  <a target='_blank' rel='noopener noreferrer' href='https://customerservice.costco.com/app/answers/answer_view/a_id/8162?r=ContactUs'>Customer Service</a>.
                                </p>
                              )}
                              <hr aria-hidden="true" />
                              {
                                this.state.stat3 === 'D' &&
                                (< h5 className="t5 text-bold">
                                  Delivered To
                                </h5>)}
                              {this.state.stat3 !== 'D' &&
                                this.state.return_flag === 'Y' &&
                                (<h5 className="t5 text-bold">
                                  Pickup From
                                </h5>)}
                              {
                                this.state.stat3 !== 'D' &&
                                this.state.return_flag !== 'Y' &&
                                (<h5 className="t5 text-bold">
                                  Deliver To
                                </h5>)
                              }
                              <p className="body-copy">
                                <AddressFormatter address={this.state.address}/>
                              </p>
                            </div>
                          )}


                        </div>


                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Conditionally render table based on results status*/}
              <div className="row">
                <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                  {this.state.tabledata && (
                    <div className="result-table">
                      <h2 className="t4 mar-btm-20">
                        Tracking History
                      </h2>
                      {
                        this.state.tabledata && this.state.tabledata.map((item) =>
                          <div>
                            <h3 className="tt5 date-head">{item.date}</h3>
                            <table>
                              <tr class="sr-only">
                                <th>Time</th>
                                <th>Status</th>
                                <th>Location</th>
                              </tr>
                              {
                                item.orderList.map((dateItem) =>
                                  <tr>
                                    <td>{dateItem.time?.toUpperCase()}</td>
                                    {this.state.trackingNewVar == 'true' ? <td>{dateItem.resultStatus}</td> : <td>{dateItem.status}</td>}
                                    <td><AddressFormatter address={dateItem.location} /></td>
                                  </tr>
                                )
                              }
                            </table>
                          </div>
                        )
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    validatedOrder: state.validatedOrder,
    messageObj: state.maintenanceAlertMessage.maintenanceMessage
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getTrackingDetails, getTrackingServiceMaintenanceAlert, getMessageToShow, getUTCTime }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(newTracking);