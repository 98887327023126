import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import { getMessageToShow, submitForSelfScheduling } from 'actions/index';
import { connect } from 'react-redux';
import 'styles/self_scheduler/_confirmationPage.scss';
import {
  setPromise,
  phoneNoFormat,
  plainPhoneNo,
  phoneNoFormatInput,
  getTransactionRef
} from 'containers/common/innovel_util';
import moment from 'moment';
import parse from 'html-react-parser';

class ConfirmationForm extends Component {
  constructor(props) {
    super(props);
    const transID = getTransactionRef();
    this.state = {
      schedulingOrder: false,
      canSchedule: true,
      isUpdate: false,
      transactionID: transID,
      alternateContact: phoneNoFormat(
      props.responseValues.workOrderList[0].trackingOrderList[0].phone
      ),
      phoneError: ''
    };
    this.errorRef = React.createRef();
  }

  setAlternateContact = e => {
    this.setState({
      alternateContact: phoneNoFormatInput(e.target.value)
    });
    var phoneno = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    if (e.target.value.match(phoneno)) {
      this.setState({
        phoneError: '',
        canSchedule: true,
        alternateContactSubmit: plainPhoneNo(e.target.value)
      });
      this.props.enableButton(true);
    } else if (e.target.value === '' || e.target.value === null || e.target.value === undefined) {
      this.setState({
        phoneError: '',
        canSchedule: true,
        alternateContactSubmit: "",
      });
      this.props.enableButton(true);   
    }
    this.props.resetForm(true);
  };

  validateAltPhone() {
    if (
      this.state.alternateContact !== '' &&
      this.state.alternateContact !== undefined &&
      this.state.alternateContact !== null
    ) {
      var phoneno = /^\s*(?:\+?([X\d]{1,3}))?[-.]*([X\d]{3})[-. ]*([X\d]{3})[-.]*([X\d]{4})(?: *x([X\d]+))?\s*$/;
      if (this.state.alternateContact.match(phoneno)) {
        this.setState({
          phoneError: '',
          canSchedule: true
        });
        return true;
      } else {
        this.setState({
          phoneError:
            'Enter a valid phone number.',
          canSchedule: false
        });
        this.props.activeSpinner(false);
        this.errorRef.current.focus();
        return false;
      }
    } else {
      return true;
    }
  }

  componentDidMount() {
    this.props.shareMethods(this.storeConfirmationDetails.bind(this));
    this.props.getMessageToShow({message: '', alertType: null});
    this.setAlternateContactSubmit();    
  }
  
  setAlternateContactSubmit = () => {
    if((this.state.alternateContact === "" || this.state.alternateContact === undefined || this.state.alternateContact === null)) {
      this.state.alternateContactSubmit = this.props.transactionDetails.orderDeliveryDetails.validPhone ? 
        (plainPhoneNo(this.props.transactionDetails.shipToPhone)
        ) : (
          plainPhoneNo(this.props.transactionDetails.orderDeliveryDetails.updatedShipToPhone));
    } else {
      this.state.alternateContactSubmit = plainPhoneNo(this.state.alternateContact);
    }

    return this.state.alternateContactSubmit;
  }
  storeConfirmationDetails = () => {
    var submitValues;
    submitValues = {
      ...submitValues,
      shipToPhone: this.state.alternateContactSubmit,
      transactionRef: this.state.transactionID
    };

    if (this.validateAltPhone()) {
      this.setState({ schedulingOrder: true });
      return this.props
        .submitForSelfScheduling(submitValues)
        .then(response => {
          this.props.disableBtn(false);
          const httpStatus = response.error === undefined ? response.payload.status : response.payload.response !== undefined ? response.payload.response.status : response.payload.request.status;
          if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
            this.props.disableBtn(false);
            const status = response.error === undefined ? response.payload.data.status : response.payload.response.data.status;
            if(status != null || status !== undefined) {
              const validStatusCode = status.statusCode != null ? "True" : "False";
              if(status.statusCode === '00' && validStatusCode) {
                if (response.payload.data.serviceResponse.status === 'inprogress') {
                  /*let messageObj = {
                    alertType: 'success',
                    message: 'Started scheduling succesfully!'
                  };
                  this.props.getMessageToShow(messageObj);*/
                  this.props.history.push('/schedule_result');
                } else {
                  if (
                    response.payload.data.serviceResponse.status !== 'inprogress'
                  ) {
                    sessionStorage.removeItem('transactionRef');
                  }
                  let messageObj = {
                    alertType: 'error',
                    message: parse(process.env.REACT_APP_MSC_CONTACT_US)
                  };
                  this.props.getMessageToShow(messageObj);
                  this.setState({ schedulingOrder: false });
                  this.props.activeSpinner(false);
                }
              }
              else {
                const errorMessage = response.error === undefined ? parse(response.payload.data.errorDetails.errors[0].message) : parse(response.payload.response.data.errorDetails.errors[0].message);

                var messageObj = {
                  alertType: 'error',
                  message: errorMessage
                };
                this.props.getMessageToShow(messageObj);
                this.props.activeSpinner(false);
              }
            }            
          } 
          else {
            var messageObj = {
              alertType: 'error',
              message: parse(process.env.REACT_APP_MSC_CONTACT_US)
            };
            this.props.disableBtn(false);
            this.props.getMessageToShow(messageObj);
            this.props.activeSpinner(false);
          }
          return response.payload;
        })
        .catch(error => {
          var messageObj = {
            alertType: 'error',
            message: parse(process.env.REACT_APP_MSC_CONTACT_US)
          };
          this.props.disableBtn(false);
          this.props.getMessageToShow(messageObj);
          this.props.activeSpinner(false);
        });
    } else {
      return setPromise(true);
    }
  };

  renderWorkingOrders = () => {
    const { workOrderList, loading } = this.props.transactionDetails;

    if(!loading && workOrderList !== null) {
      return(
        <div>
          {workOrderList.map((order) => (
            <div style={{marginBottom: '1rem'}}>
              <div style={{border: '1px solid #909090', backgroundColor: '#FAFAFA', marginTop: '20px', boxShadow: '2px 2px 3px #ccc'}}>
                <div className="container-fluid  confirmation-body orderBodyPanel" style={{marginLeft: "-16px", marginTop: "15px"}}>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: "bold"}}>Order Number</span><br role="presentation" />
                      {order.trackingOrderList[0].workOrderNumber || 'Not Available'}
                    </div>
                  </div>
                  <div className="row" style={{marginBottom: "10px"}}>
                    <div className="col">
                      <span style={{fontWeight: "bold"}}>Order Date</span><br role="presentation" />
                      {order.trackingOrderList[0].orderCreatedDate}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span style={{fontWeight: 'bold'}}>Tracking Number(s)</span><br role="presentation" />
                        <ul style={{listStyleType: 'none', padding: 0}}>
                          {order.trackingOrderList.map(tracking => 
                            <div>
                              {tracking.workOrderNumber === order.workOrderNumber &&
                              (<li>{tracking.trackingNumber}</li>)}
                            </div>
                          )}
                        </ul>                
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          ))}
        </div>
      )
    }
  }

  render() {
    return (
      <div style={{ marginBottom: '1rem' }}>
        {this.renderWorkingOrders()}
        
        <div style={{border: '1px solid #909090', backgroundColor: '#FAFAFA', marginTop: '20px', boxShadow: '2px 2px 3px #ccc'}}>
          <div className="confirmation-body orderBodyPanel" style={{ marginTop: "15px" }}>
            <div className="row" style={{marginBottom: "10px"}}>
              <div className="col">
                {this.props.transactionDetails.scheduleTransactionDetail
                  .scheduleOrderType === 4 && (
                    <div>
                      <span style={{fontWeight: 'bold'}}>Requested Delivery/Return Pickup Date</span><br role="presentation" />
                      {moment(this.props.transactionDetails.scheduleDate).format('LLLL').substring(0, moment(this.props.transactionDetails.scheduleDate).format('LLLL').length - 9)}
                    </div>
                )}
              </div>
            </div>
            {(this.props.transactionDetails.startTime !== "" &&
             this.props.transactionDetails.timeWindowFlag) && <div className="row" style={{marginBottom: "10px"}}>
              <div className="col">
                {this.props.transactionDetails.scheduleTransactionDetail
                  .scheduleOrderType === 4 && (
                    <div>
                      <span style={{fontWeight: 'bold'}}>Requested Time Window</span><br role="presentation" />
                      {(this.props.transactionDetails.startTime !== null || this.props.transactionDetails.startTime !== "") ? moment.utc(this.props.transactionDetails.startTime, ["HH:mm"]).format('h:mm a') + " - " + moment.utc(this.props.transactionDetails.endTime, ["HH:mm"]).format('h:mm a') : "Not Selected"}
                    </div>
                )}
              </div>
            </div>}
            <div className="row" style={{marginBottom: "10px"}}>
              <div className="col">
                <span style={{fontWeight: 'bold'}}>Address</span><br role="presentation" />
                {this.props.transactionDetails.shipToAddress1 + ', ' + this.props.transactionDetails.shipToCity + ', ' + this.props.transactionDetails.shipToState + ' ' + this.props.transactionDetails.shipToZip}
              </div>
            </div>
            <div className="row" style={{marginBottom: "10px"}}>
              <div className="col">
                <span style={{fontWeight: 'bold'}}>Email</span><br role="presentation" />
                {this.props.transactionDetails.customerEmail}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span style={{fontWeight:'bold'}}>Phone</span><br role="presentation" />
                {
                  this.props.transactionDetails.orderDeliveryDetails
                    .validPhone ? (
                    phoneNoFormat(this.props.transactionDetails.shipToPhone)
                  ) : (
                    phoneNoFormat(
                      this.props.transactionDetails.orderDeliveryDetails
                        .updatedShipToPhone
                    )
                  )
                }<br role="presentation" /><br role="presentation" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                    fullWidth
                    inputRef={this.errorRef}
                    error={!this.state.canSchedule}
                    id="alternate-phone"
                    label="Alternate Phone"
                    aria-describedby={"alternate-phone-label"}
                    value={this.state.alternateContact}
                    variant="outlined"
                    onChange={this.setAlternateContact}
                    disabled={this.props.alternateDisabled}
                    className="txtField"
                    autoComplete="off"
                  />
                  {this.state.phoneError && <div id="err-help" className='altPhoneErrMsg'>{(this.state.phoneError)}</div>}<br role="presentation" /><br role="presentation" />
              </div>
            </div>            
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails
  };
}

function validate(values) {
  const errors = {};
  return errors;
}
export default reduxForm({
  validate,
  form: 'ConfirmationForm'
})(
  connect(mapStateToProps, {
    getMessageToShow,
    change,
    submitForSelfScheduling
  })(ConfirmationForm)
);
