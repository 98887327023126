import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTransactionDetails, getMessageToShow, getUTCTime } from 'actions/index';
import { 
  Button,
  Grid, 
  Typography
} from '@mui/material';
import { styled } from "@mui/material/styles";
import moment from 'moment';
import { FaLeaf } from "react-icons/fa";
import TimeWindowTimer from '../../components/timeWindowTimer';
import { TrackingSpinner } from 'containers/common/tracking_spinner';

const WindowButton = styled(Button)(({ theme }) => ({
  display: "block",
  textTransform: "none",
  border: "2px solid",
  width: "100%",
  height: "50px",
  padding: "0px",
  boxShadow: "2px 2px 5px #ccc",
  '&.MuiButton-root': { 
    '&:hover': {
      border: "2px solid",
      borderColor: "#008000",
      backgroundColor: "#F4FFF6 !important",
      color: "#008000"
    },
    '&:focus': {
      borderColor: "#008000 !important"
    }  
  }
}));

const ButtonIcon = styled(FaLeaf)(({theme}) => ({
  color: "#008000",
  // width: {xs: "8%", sm: "8%", md: "8%"}
}));

export class TimeWindow extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      scheduleDate: "",
      preSelectedTWExists: false,
      windowSelected: false,
      windowId: "",
      greenLeafMatched: false,
      calendarDate: "",
      buttonActive: false,
      loadingComplete: false,
      loadSpinner: false,
      greenFlagAvailable: false,
      buttonClicked: false,
      fullDayTW: false,
      hjFullDayTW: false,
      displayNoTWMessageSameDate: JSON.parse(process.env.REACT_APP_DISPLAY_NO_TW_MESSAGE_SAME_DATE),
      noTWErrorMessageSameDate: process.env.REACT_APP_NO_TW_MESSAGE_SAME_DATE,
      displayNoTWMessageDiffDate: JSON.parse(process.env.REACT_APP_DISPLAY_NO_TW_MESSAGE_DIFF_DATE),
      noTWErrorMessageDiffDate: process.env.REACT_APP_NO_TW_MESSAGE_DIFF_DATE,
      displayCustomButton: true,
    }
  }

  ValidateTimeWindow = (date, startTime, endTime) => {
    var scheduledStartTime = moment(date + " " + startTime, ["YYYY-MM-DD HH:mm"]);
    var scheduledEndTime = moment(date + " " + endTime, ["YYYY-MM-DD HH:mm"]);

    var fullDayTW = moment(scheduledEndTime).diff(scheduledStartTime, 'hours') > process.env.REACT_APP_TIME_WINDOW_RANGE ? true : false;

    return fullDayTW;
  }

  componentDidMount() {
    this.props.shareMethods(this.renderTimeWindows.bind(this));
    const {unselectedTW} = this.props.transactionDetails;
    const unselectedTWList = this.props.transactionDetails.unselectedTW;
    if (unselectedTWList && Array.isArray(unselectedTWList) && !this.props.timeWindows.length) {
      unselectedTW.map(item => {
        if (item.hjScheduleDate === this.props.changedDate && this.props.transactionDetails.hjStartTime !== item.hjStartTime && this.props.transactionDetails.hjEndTime !== item.hjEndTime) {
          this.setState({
            displayCustomButton: false,
          });
        }
      })
    }
    if(this.props.transactionDetails.startTime !== undefined && this.props.transactionDetails.startTime !== null
      && this.props.transactionDetails.startTime !== "") { 
        var fullDayTW = this.ValidateTimeWindow(this.props.transactionDetails.scheduleDate, 
          this.props.transactionDetails.startTime, this.props.transactionDetails.endTime)
        this.setState({ fullDayTW: fullDayTW });
        this.setState({ 
          hjFullDayTW: this.ValidateTimeWindow(this.props.transactionDetails.hjScheduleDate, 
          this.props.transactionDetails.hjStartTime, this.props.transactionDetails.hjEndTime)
        });

        var startTime = "";
        var endTime = "";
        var selectedWindow = false;
        var routeID = "";
        var stopNumber = "";
        var score = "";
        var greenLeafAvailable = false;
        var greenLeafSelected = false;
        if(!fullDayTW) {          
          if(this.props.changedDate !== null && this.props.transactionDetails.scheduleDate === this.props.changedDate) {
            const preSelectedTW = moment.utc(this.props.transactionDetails.startTime, ["HH:mm"]).format('hh:mma');
            if(Array.isArray(this.props.timeWindows) && this.props.timeWindows.length) {
              const greenLeafFound = this.props.timeWindows[0].timeWindowsList.find(leaf => leaf.greenFlag) ? true : false;
              const existingWindow = this.props.timeWindows[0].timeWindowsList.find(window => window.startTime === this.props.transactionDetails.startTime);
              const greenLeafPreselected = this.props.timeWindows[0].timeWindowsList.find(window => window.startTime === this.props.transactionDetails.startTime && window.greenFlag) ? true : false;
              routeID = existingWindow ? existingWindow.routeID : "";
              stopNumber = existingWindow ? existingWindow.stopNumber : "";
              score = existingWindow ? existingWindow.score : "";
              greenLeafAvailable = greenLeafFound;
              greenLeafSelected = greenLeafPreselected;
            }
            this.setState({ scheduleDate: this.props.transactionDetails.scheduleDate, preSelectedTWExists: true, windowId: preSelectedTW + "selected", windowSelected: true });
            if(moment(this.props.transactionDetails.scheduleDate).isSameOrAfter(Date())) {
              startTime = this.props.transactionDetails.startTime;
              endTime = this.props.transactionDetails.endTime
              selectedWindow = true;
            } 
            // else {
            //   startTime = "";
            //   endTime = "";
            //   selectedWindow = false;
            // }
          } else {
            if(this.props.changedDate === this.props.transactionDetails.hjScheduleDate) {
              if(this.props.transactionDetails.hjStartTime !== undefined && this.props.transactionDetails.hjStartTime !== null
                && this.props.transactionDetails.hjStartTime !== "") {
                  var hjFullDayTW = this.ValidateTimeWindow(this.props.transactionDetails.hjScheduleDate, 
                    this.props.transactionDetails.hjStartTime, this.props.transactionDetails.hjEndTime)
                  this.setState({ fullDayTW: hjFullDayTW });
                  
                  if(!hjFullDayTW) {
                    const preSelectedTW = moment.utc(this.props.transactionDetails.hjStartTime, ["HH:mm"]).format('hh:mma');
                    if(Array.isArray(this.props.timeWindows) && this.props.timeWindows.length) {
                      const greenLeafFound = this.props.timeWindows[0].timeWindowsList.find(leaf => leaf.greenFlag) ? true : false;
                      const existingWindow = this.props.timeWindows[0].timeWindowsList.find(window => window.startTime === this.props.transactionDetails.hjStartTime);
                      const greenLeafPreselected = this.props.timeWindows[0].timeWindowsList.find(window => window.startTime === this.props.transactionDetails.hjStartTime && window.greenFlag) ? true : false;
                      routeID = existingWindow ? existingWindow.routeID : "";
                      stopNumber = existingWindow ? existingWindow.stopNumber : "";
                      score = existingWindow ? existingWindow.score : "";
                      greenLeafAvailable = greenLeafFound;
                      greenLeafSelected = greenLeafPreselected;
                    }
                    
                    this.setState({ preSelectedTWExists: true, windowId: preSelectedTW + "selected" });
                    if(moment(this.props.transactionDetails.scheduleDate).isSameOrAfter(Date())) {
                      startTime = this.props.transactionDetails.hjStartTime;
                      endTime = this.props.transactionDetails.hjEndTime
                      selectedWindow = true;
                    }
                  } else {
                    selectedWindow = Array.isArray(this.props.timeWindows) && this.props.timeWindows.length ? false : true;
                  }
                }
            }
          }          
        } else {
          selectedWindow = Array.isArray(this.props.timeWindows) && this.props.timeWindows.length ? false : true;
        }
       
        let windowData = {};
        windowData = {
          startTime: startTime,
          endTime: endTime,
          windowSelected: selectedWindow,
          greenFlagAvailable: greenLeafAvailable,
          greenFlagSelected: greenLeafSelected,
          routeID: routeID,
          stopNumber: stopNumber,
          score: score
        };
        this.props.timeWindowData(windowData);
        

      } else {        
        var selection = false;
        if(this.props.timeWindows !== null && this.props.timeWindows.length) {
          selection = false;
        } else {
          selection = true;
        }

        var hjStartTime = "";
        var hjEndTime = "";
         if(this.props.changedDate !== null 
          && this.props.transactionDetails.hjScheduleDate === this.props.changedDate 
          && this.props.transactionDetails.hjStartTime) {
            var hjFullDayTW = this.ValidateTimeWindow(this.props.transactionDetails.hjScheduleDate, 
              this.props.transactionDetails.hjStartTime, this.props.transactionDetails.hjEndTime)
            this.setState({ fullDayTW: hjFullDayTW });
            
            if(!hjFullDayTW) {
              hjStartTime = this.props.transactionDetails.hjStartTime;
              hjEndTime = this.props.transactionDetails.hjEndTime;
            }
         }
        
        let windowData = {};
        windowData = {
          startTime: hjStartTime,
          endTime: hjEndTime,
          windowSelected: selection,
          greenFlagAvailable: false,
          greenFlagSelected: false,
          routeID: "",
          stopNumber: "",
          score: ""
        };
        this.props.timeWindowData(windowData);
      }
  }

  componentWillReceiveProps(nextProps) {
    if(Array.isArray(nextProps.timeWindows) && nextProps.timeWindows.length) {
      this.setState({ scheduleDate: nextProps.timeWindows[0].deliveryDate, timeWindows: nextProps.timeWindows, loadingComplete: nextProps.loadingComplete });
      if(nextProps.changedDate !== undefined && nextProps.changedDate !== null && nextProps.changedDate !== "") {
        this.setState({ calendarDate: nextProps.changedDate });
        var preSelectedTW = "";
        if(nextProps.changedDate === this.props.transactionDetails.scheduleDate) {
          preSelectedTW = moment.utc(this.props.transactionDetails.startTime, ["HH:mm"]).format('hh:mma');
          this.setState({ windowSelected: preSelectedTW + "selected", buttonActive: true });
        } else if(nextProps.changedDate === this.props.transactionDetails.hjScheduleDate) {
          preSelectedTW = moment.utc(this.props.transactionDetails.hjStartTime, ["HH:mm"]).format('hh:mma');
          this.setState({ windowSelected: preSelectedTW + "selected", buttonActive: true });
        }
        else {
          if(!this.state.buttonClicked) {
            this.setState({ windowSelected: preSelectedTW, buttonActive: false });
          }
        }
      }
    }
}

noDCEWindowsResponse = () => {
  var noTWSameDate = this.state.noTWErrorMessageSameDate.replace('%dt', moment.utc(this.props.transactionDetails.hjScheduleDate).format('LLL').substring(0, moment.utc(this.props.transactionDetails.hjScheduleDate).format('LLL').length - 8));
  var noTWDiffDate = this.state.noTWErrorMessageDiffDate.replace('%dt', moment.utc(this.props.changedDate).format('LLL').substring(0, moment.utc(this.props.changedDate).format('LLL').length - 8));
  if(!this.state.fullDayTW) {        
    return(
      <div>
        <p><span style={{ fontWeight: "bold" }}>Time Window</span></p>
        <p>
          {this.state.displayCustomButton && (this.props.transactionDetails.scheduleDate && this.props.transactionDetails.hjScheduleDate === this.props.changedDate && this.props.transactionDetails.hjStartTime) &&
            <div>
            <Grid container rowSpacing={2} columnSpacing={1} aria-label="Time Windows">              
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <WindowButton
              variant="outlined"
              sx={{borderColor: "#008000 !important", color: "#008000", backgroundColor: "#F4FFF6"}}>
                <Typography 
                id={this.props.transactionDetails.hjStartTime + "*" + this.props.transactionDetails.hjEndTime} 
                sx={{ fontSize: {xs: "15px", sm: "15px", md: "14px", lg: "15px"} }}
                >
                  {(moment.utc(this.props.transactionDetails.hjStartTime, ["HH:mm"]).format('h:mma'))} - {(moment.utc(this.props.transactionDetails.hjEndTime, ["HH:mm"]).format('h:mma'))}
                </Typography>
              </WindowButton>
            </Grid>
          </Grid></div>}
          {(this.state.displayNoTWMessageSameDate && this.props.transactionDetails.hjScheduleDate === this.props.changedDate && !this.props.transactionDetails.hjStartTime) &&
            <div class="dceNoWindows">
              <p>
                <span>{noTWSameDate}</span>
              </p>
            </div>
          }
          {(this.state.displayNoTWMessageDiffDate && this.props.transactionDetails.hjScheduleDate !== this.props.changedDate) &&
            <div class="dceNoWindows">
              <p>
                <span>{noTWDiffDate}</span>
              </p>
            </div>
          }
        </p>
      </div>
    )
  } else {
    return(
      <div>
        <p><span style={{ fontWeight: "bold" }}>Time Window</span></p>
        <p>
          {(this.state.displayNoTWMessageSameDate && this.props.transactionDetails.hjScheduleDate === this.props.changedDate) &&
            <div class="dceNoWindows">
              <p>
                <span>{noTWSameDate}</span>
              </p>
            </div>
          }
          {(this.state.displayNoTWMessageDiffDate && this.props.transactionDetails.hjScheduleDate !== this.props.changedDate) &&
            <div class="dceNoWindows">
              <p>
                <span>{noTWDiffDate}</span>
              </p>
            </div>
          }
        </p>
      </div>
    )
  }
}

  renderTimeWindows = () => {
    const validDate = this.props.requestDate === this.props.changedDate ? true : false;
    if(this.state.timeWindows !== null && this.state.timeWindows !== undefined) {      
      let timeWindows = "";
      timeWindows = this.state.timeWindows;
      if(Array.isArray(timeWindows) && timeWindows.length && validDate) {
        if(timeWindows[0].timeWindowsList !== null && timeWindows[0].timeWindowsList !== undefined &&
          Array.isArray(timeWindows[0].timeWindowsList) && timeWindows[0].timeWindowsList.length) {
          const dateMatched = timeWindows.find(day => (moment.utc(day.deliveryDate).format('YYYY-MM-DD')) === this.state.scheduleDate);
          const greenLeafFound = dateMatched.timeWindowsList.find(leaf => leaf.greenFlag) ? true : false;
          const duplicateWindow = dateMatched.timeWindowsList.find(window => window.startTime === this.props.transactionDetails.hjStartTime) ? true : false;
          if(this.state.preSelectedTWExists && !duplicateWindow 
            && this.props.transactionDetails.hjStartTime !== null 
            && this.props.transactionDetails.hjStartTime !== "" 
            && this.state.calendarDate === this.props.transactionDetails.hjScheduleDate
            && !this.state.hjFullDayTW) {
            let customWindow = {
              startTime: this.props.transactionDetails.hjStartTime,
              endTime: this.props.transactionDetails.hjEndTime,
              greenFlag: false,
              stopNumber: "",
              routeID: "",
              score: ""
            }
            dateMatched.timeWindowsList.push(customWindow);          
          }
          dateMatched.timeWindowsList.sort((a,b) => (a.startTime > b.startTime) ? 1 : ((b.startTime > a.startTime) ? -1 : 0));

          if(Array.isArray(dateMatched.timeWindowsList)) {
            return(
              <div>
                {this.state.loadingComplete && <div>
                  <p><span style={{ fontWeight: "bold" }}>Time Window</span></p>
                  <p>                  
                    <Grid container rowSpacing={2} columnSpacing={1} aria-label="Time Windows">
                      {dateMatched.timeWindowsList.map((windowSlot, index) => (
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <WindowButton
                          disableRipple
                          variant="outlined"                 
                          key={index}
                          onClick={e => this.handleOnClick(windowSlot, greenLeafFound)}
                          id={windowSlot.startTime + "*" + windowSlot.endTime + "*" + windowSlot.greenFlag + "*" + windowSlot.routeID + "*" + windowSlot.stopNumber + "*" + greenLeafFound }
                          focusVisibleClassName='activeClass'
                          sx={this.state.buttonActive && (this.state.windowId === moment.utc(windowSlot.startTime, ["HH:mm"]).format('hh:mma') + "selected") ? {borderColor: "#008000 !important", color: "#008000", backgroundColor: "#F4FFF6"} : ""}
                          >
                            {windowSlot.greenFlag ? 
                            <Typography
                            sx={{ pointerEvents: "none", padding: "0px 5px 0px 0px", marginBottom: {xs: "-11px", sm: "-10px", md: "-10px", lg: "-8px"}, marginTop: {xs: "-15px", sm: "-15px", md: "-15px", lg: "-12px"}, textAlign: "right" }}
                            >
                              {<ButtonIcon sx={{ marginBottom: {xs: "8px",sm: "4px", md: "8px", lg: "6px"}, width:{xs: "10%", sm: "10%", md:"9%", lg: "8%"}}} />}
                            </Typography> : ""}
                            <Typography 
                            sx={{ pointerEvents: "none", fontSize: {xs: "15px", sm: "14px", md: "14px", lg: "1.03em"} }}
                            >
                              {moment.utc(windowSlot.startTime, ["HH:mm"]).format('h:mma')} - {moment.utc(windowSlot.endTime, ["HH:mm"]).format('h:mma')}
                            </Typography>
                          </WindowButton>
                        </Grid>
                      ))}
                    </Grid>
                    <TimeWindowTimer />
                    {greenLeafFound && <div className="col" style={{ marginTop: "8px", fontSize: "14px"}}>
                      <span>{<FaLeaf color="#008000" />} Eco-Friendly</span>
                    </div>}
                  </p>  
                </div>}
              </div>
            )    
          }
        } else {
          return (
            <div>
              {this.noDCEWindowsResponse()}
            </div>
            )
        }
      }
    } else {
      return(
      <div>
        {validDate && this.noDCEWindowsResponse()}
      </div>
      )
    }
  }
  

  handleOnClick = (windowProps, greenLeafAvailable) => {
    // const timeWindow = e.target.id ? e.target.id : e.target.offsetParent.id;
    // const timeSpots = timeWindow.split('*');
    const startTime = (moment.utc(windowProps.startTime, ["HH:mm"]).format('hh:mma'));
    this.state.buttonClicked = true;
    this.setState({ buttonActive: true, windowSelected: windowProps.startTime !== "" ? true : false, windowId: startTime + "selected" });

    let windowData = {
      startTime: windowProps.startTime,
      endTime: windowProps.endTime,
      windowSelected: true,
      greenFlagAvailable: greenLeafAvailable !== null ? greenLeafAvailable : "",
      greenFlagSelected: windowProps.greenFlag !== null ? windowProps.greenFlag : "",
      routeID: windowProps.routeID,
      stopNumber: windowProps.stopNumber ? windowProps.stopNumber : "",
      score: windowProps.score ? windowProps.score : ""
    };
    this.props.timeWindowData(windowData);
 }

    render() {
        return (
            <div>
                {this.renderTimeWindows()}
            </div>
        )
    }
};

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails,
    timeWindows: state.timeWindows
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators({ getTransactionDetails, getMessageToShow, getUTCTime }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (TimeWindow);