import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from './common/ButtonComponent.js';
import Link from '@mui/material/Link';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  validateTrackNbrForScheduling,
  getStarter,
  getMessageToShow,
  getOrderServiceMaintenanceAlert,
  fetchNewData,
  getUTCTime
} from 'actions/index';
import _ from 'lodash';
import 'styles/self_scheduler/_startPage.scss';
import { TrackingSpinner } from 'containers/common/tracking_spinner';
import {
  clearSessionStorage,
  setSessionStorage,
  checkboxSessionStorage,
  RegexMatch,
  encryptedString,
  decryptedString
} from 'containers/common/innovel_util';
import moment from 'moment';
import LogoffLayer from './logoffLayer';
import InnovelServiceAlert from 'containers/common/innovel_service_alert.js';
import parse from 'html-react-parser';

class StartPage extends Component {
  constructor(props) {
    super(props);
    this.topReference = React.createRef();
    this.state = {
      loading: false,
      orderValid: true,
      trackNumMissing: false,
      transactionDetails: null,
      trackingNumber: this.getUrlParameter('order'),
      scheduleStatus: '',
      spinTheSpinner: false,
      getStarted: false,
      multipleOrders: false,
      restart: false,
      selectedOrders: [],
      selectAllChecked: false,
      clientCode: '',
      mscPhone: '',
      allowToSchedule: '',
      disableSubmission: false,
      trackingHeader: false,
      displayOrder: '',
      orderDate: '',
      showErrorMessage: false,
      errorMessage: '',
      callError: false,
      ordersAvailable: false,
      disableBtn: false,
      loadRender: false,
      orderKey: this.orderKeySetup(),
      keySource: this.getOrderKey() ? process.env.REACT_APP_EMAIL_INDICATOR : process.env.REACT_APP_PORTAL_INDICATOR
    };
  }

  getUrlParameter = name => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  getOrderKey = () => {
    var url = document.URL;
    const params = new URLSearchParams(url);
    const newParams = new URLSearchParams(
      Array.from(params, ([key, value]) => [key.toLowerCase(), value])
    );
    // let orderKeyParam = params.get('orderKey') || params.get('Orderkey') || params.get('ORDERKEY') || params.get('orderkey') || params.get('OrderKey');
    let orderKeyParam = newParams.get('orderkey');

    return orderKeyParam;
  }

  orderKeySetup = () => {    
    var initVal = this.getOrderKey();
    var orderKeyVal = "";

    if(initVal && initVal !== undefined && initVal !== null) {
      orderKeyVal = initVal.length > 4 ? initVal :"";
    } else {
      var localStKey = localStorage.getItem("key") ? localStorage.getItem("key") : "";
      if(localStKey && localStKey !== undefined && localStKey !== null && localStKey !== "") {
        var decKey = decryptedString(localStKey, process.env.REACT_APP_SECRET);
        orderKeyVal = JSON.parse(decKey).orderKey;
      }
    }

    return orderKeyVal;
  }

  componentDidMount() {
    var tmpUrl = window.location.href;
    var urlComp = tmpUrl.split('&');
    localStorage.setItem('bulkUrl', urlComp[0]);
    this.setState({ restart: false });
    var messageObj = {
      alertType: 'reset',
      message: ''
    };
    this.props.getMessageToShow(messageObj);
    this.topReference.current.scrollIntoView();

    if (
      this.state.trackingNumber === undefined ||
      this.state.trackingNumber === null ||
      this.state.trackingNumber === ''
    ) {
      this.displayErrorMessage("Order cannot be scheduled On-Line. Tracking or Order Number missing.");

      this.setState({ orderValid: false, trackNumMissing: true });
    }


    this.props.getOrderServiceMaintenanceAlert().then(result => {
      var response = result.payload.data;
      if (result.payload.status === 200) {

        let alertActive = (response?.serviceResponse?.alertMessageResponse?.messages["scheduler.alert.action.disable"] === "true"
          && response?.serviceResponse?.alertMessageResponse?.messages["scheduler.maintenance.message"] !== "")
          ? true : false;

        if (alertActive === "false" || alertActive === false) {
          clearSessionStorage();
          this.setState({
            loading: true,
            trackNumMissing: false,
          });
          if ((this.state.trackingNumber !== '' || this.state.trackingNumber !== null || this.state.trackingNumber !== undefined) && RegexMatch(this.state.trackingNumber, 1)) {
            this.props.getUTCTime().then(result => {
              const httpStatus = result.payload.status
              if (httpStatus === 200) {
                //var response = result.payload.data;
                sessionStorage.setItem("utcTime", result.payload.request.response)
              }
              else {
                sessionStorage.removeItem("utcTime");
              }
              if(this.state.orderKey && this.state.orderKey !== undefined && this.state.orderKey !== null) { this.ServiceApiCall() } else { this.redirectUserToLandingPage() }
            }).catch(error => {
              if(this.state.orderKey && this.state.orderKey !== undefined && this.state.orderKey !== null) { this.ServiceApiCall() } else { this.redirectUserToLandingPage() }
            });
          }
          else {
            this.redirectUserToLandingPage();
          };
        } else {
          window.location.replace("/");
        }
      }
      else {
        if(this.state.orderKey && this.state.orderKey !== undefined && this.state.orderKey !== null) { this.ServiceApiCall() } else { this.redirectUserToLandingPage() }
      }
    }).catch(error => {
      if(this.state.orderKey && this.state.orderKey !== undefined && this.state.orderKey !== null) { this.ServiceApiCall() } else { this.redirectUserToLandingPage() }
    });
    // Google Analytics event
    window.gtag('config', process.env.REACT_APP_GTAG_ID, {
      'page_title': 'Schedule MY Deliver Email',
      'page_path': '/selfschedule'
    });
  }

  redirectUserToLandingPage = () => {
    localStorage.setItem("source", "scheduling");
    var encKey = encryptedString(JSON.stringify({
      'orderKey': this.state.orderKey,
      'keySource': this.state.keySource
    }), process.env.REACT_APP_SECRET);
    localStorage.setItem("key", encKey.toString()); 
    this.props.history.push("/userselfschedule?order=" + this.state.trackingNumber);
  }

  ServiceApiCall = () => {
    this.props.fetchNewData(this.state.trackingNumber);
    this.props.validateTrackNbrForScheduling(this.state.trackingNumber, this.state.orderKey, this.state.keySource).then(result => {
      const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
      if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
        var encKey = encryptedString(JSON.stringify({
          'orderKey': this.state.orderKey,
          'keySource': this.state.keySource
        }), process.env.REACT_APP_SECRET);
        localStorage.setItem("key", encKey.toString());  
        const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
        if (status !== null || status !== undefined) {
          const validStatusCode = status.statusCode != null ? "True" : "False";
          if (status.statusCode === '00' && validStatusCode) {
            this.setState({ loading: false, loadRender: true });
          }
          else {
            const serviceResponse = result.error === undefined ? result.payload.data.serviceResponse : result.payload.response.data.serviceResponse;
            if (serviceResponse === null || serviceResponse === undefined) {
              this.redirectUserToLandingPage();
            }
            else {
              //Setting the error state to display harcoded message
              this.setState({ callError: true, disableBtn: false });
              const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message;
              this.displayErrorMessage(errorMessage);
            }
            return;
          }
        }
      }
      else {
        this.setState({ callError: true, disableBtn: false });
        this.displayErrorMessage(process.env.REACT_APP_MSC_CONTACT_US);
      }
    }).catch(error => {
      this.setState({ callError: true, disableBtn: false });
      this.displayErrorMessage(process.env.REACT_APP_MSC_CONTACT_US);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.transactionDetails && this.props.transactionDetails.serverError) {
      this.props.getMessageToShow({
        alertType: 'error',
        message: parse(this.props.transactionDetails.errorMessage)
      });
    }
    if (this.props.transactionDetails && prevProps.transactionDetails.workOrderList !== this.props.transactionDetails.workOrderList) {
      const { workOrderList = [] } = this.props.transactionDetails;
      //const { workOrderList = [] } = Mock[0].serviceResponse;
      if (Array.isArray(workOrderList)) {
        const selectedOrders = workOrderList.map(order => {
          return {
            workOrderNumber: order.workOrderNumber,
            trackingOrderList: order.trackingOrderList.map(item => ({ selected: item.selected, trackingNumber: item.trackingNumber, allowToSchedule: item.allowToSchedule, primary: item.primary, orderDeliveryStatus: item.orderDeliveryStatus }))
          }
        });
        if (workOrderList.length === 1 && Array.isArray(workOrderList[0].trackingOrderList) && workOrderList[0].trackingOrderList.length === 1) {
          if (workOrderList[0].trackingOrderList[0].allowToSchedule) {
            selectedOrders[0].trackingOrderList[0].selected = true;
          }
        }
        this.props.getMessageToShow({});
        this.setState({ selectedOrders, orderValid: true });
        var orderdate = moment(this.props.transactionDetails.servDate).format('MM/DD/YYYY');
        this.setState({ orderDate: orderdate });

        selectedOrders.forEach(order => {
          for (let i = 0; i < order.trackingOrderList.length; i++) {
            if (order.trackingOrderList[i].allowToSchedule && order.trackingOrderList[i].orderDeliveryStatus === "ND") {
              this.setState({ ordersAvailable: true });
              break;
            }
          }
        })
      }
      else {
        this.setState({ orderValid: false });
      }
    }
  }

  displayErrorMessage = (errorMessage) => {
    let messageObj = {
      alertType: 'error',
      message: parse(errorMessage)
    };
    this.setState({ showErrorMessage: true, errorMessage: messageObj.message });
    this.setState({ loading: false });
    this.topReference.current.scrollIntoView()
  };

  noServiceError = (message) => {
    this.setState({
      orderValid: false,
      loading: false,
      spinTheSpinner: false
    });
    this.displayErrorMessage(message);
  }

  getCurrentSchOrdType =
    () => {
      return this.props.transactionDetails
        && this.props.transactionDetails.scheduleTransactionDetail
        && this.props.transactionDetails.scheduleTransactionDetail.scheduleOrderType;
    }

  getClientCode = () => {
    return this.props.transactionDetails.clientCode;
  }

  handleClick = () => {
    this.props.getUTCTime().then(result => {
      const httpStatus = result.payload.status
      if (httpStatus === 200) {
        //var response = result.payload.data;
        sessionStorage.setItem("utcTime", result.payload.request.response)
      }
      else {
        sessionStorage.removeItem("utcTime");
      }
      this.getStarted()
    }).catch(error => {
      sessionStorage.removeItem("utcTime");
      this.getStarted()
    });
  }

  getStarted = () => {
    // To avoid multiple button clicks
    if (this.state.disableBtn) {
      return
    }
    this.setState.disableBtn = true;
    this.topReference.current.scrollIntoView()
    const payload = [];
    let isTrackingOrderSelected = false;
    let isOrderSchedulable = false;
    this.state.selectedOrders.forEach((workorder, wIndex) => {
      if (workorder.trackingOrderList) {
        workorder.trackingOrderList.forEach((workOrderList, tIndex) => {
          if (workOrderList.allowToSchedule) {
            isOrderSchedulable = workOrderList.allowToSchedule;
          }
          if (workOrderList.selected) {
            isTrackingOrderSelected = workOrderList.selected;
          }
          payload.push({
            ...workOrderList,
            workOrderNumber: workorder.workOrderNumber
          })
        })
      }
    })
    if (isOrderSchedulable) {
      if (isTrackingOrderSelected) {
        this.setState({ spinTheSpinner: true, loading: true }, () => {
          this.props
            .getStarter(payload)
            .then(result => {
              this.setState({ disableBtn: false });
              const httpStatus = result.error === undefined ? result.payload.status : result.payload.response !== undefined ? result.payload.response.status : result.payload.request.status;
              if (httpStatus === 200 || httpStatus === 400 || httpStatus === 500) {
                const status = result.error === undefined ? result.payload.data.status : result.payload.response.data.status;
                if (status !== null || status !== undefined) {
                  const validStatusCode = status.statusCode != null ? "True" : "False";
                  if (status.statusCode === '00' && validStatusCode) {
                    const obj =
                      result.payload.data.serviceResponse.scheduleTransactionDetail
                        .pageNavigation;
                    setSessionStorage(
                      result.payload.data.serviceResponse.transactionRef,
                      this.state.trackingNumber
                    );
                    this.props.getMessageToShow({ alertType: 'error', message: '' });
                    const navigation = _.entries(obj);
                    let pageToPush = this.findPageName(navigation, 0);
                    this.props.history.push('/' + pageToPush);
                    return;
                  } else {
                    this.setState({
                      orderValid: false,
                      loading: false,
                      spinTheSpinner: false
                    });
                    const errorMessage = result.error === undefined ? result.payload.data.errorDetails.errors[0].message : result.payload.response.data.errorDetails.errors[0].message
                    this.displayErrorMessage(errorMessage);
                  }
                }
                else {
                  this.noServiceError(process.env.REACT_APP_MSC_CONTACT_US);
                }
              }
              else {
                this.noServiceError(process.env.REACT_APP_MSC_CONTACT_US);
              }
            })
            .catch(error => {
              this.setState({ callError: true, disableBtn: false });
              this.displayErrorMessage(process.env.REACT_APP_MSC_CONTACT_US);
            });
        });
      } else {
        this.setState({ disableBtn: false });
        this.displayErrorMessage('At least one tracking number must be selected to begin scheduling.');
      }
    } else {
      this.setState({ disableBtn: false });
      this.props.getMessageToShow({
        alertType: 'error',
        message: 'Order or tracking numbers are unable to be scheduled online. For more details see “Status” message for the tracking number.'
      })
    }
  };

  findPageName = (myArray, value) => {
    for (let i = 0; i < myArray.length; i++) {
      if (myArray[i][1] === value) {
        return myArray[i][0];
      }
    }
  };

  handleSelection = (checked, workOrderNumber, selectedIndex) => {
    const { selectedOrders } = this.state;
    const matchingWorkOrder = selectedOrders.find(order => order.workOrderNumber === workOrderNumber);
    if (matchingWorkOrder) {
      const { trackingOrderList = [] } = matchingWorkOrder;
      const updatedTrackingOrderList = trackingOrderList.map((order, index) => {
        if (order.trackingNumber === selectedIndex) {
          order.selected = checked;
          checkboxSessionStorage(order.trackingNumber, checked);
        }
        return order;
      });

      selectedOrders.map(order => {
        if (order.workOrderNumber === workOrderNumber) {
          order.trackingOrderList = updatedTrackingOrderList
        }
        return order;
      })
    }

    this.setState({
      selectedOrders
    });
  };

  handleSelectAll = (checked) => {
    const { selectedOrders = [] } = this.state;

    if (selectedOrders.length > 0) {
      selectedOrders.map(order => {
        order.trackingOrderList.forEach(tracking => {
          if (tracking.allowToSchedule) {
            tracking.selected = checked;
            checkboxSessionStorage(tracking.trackingNumber, checked);
          }
        })
        return order;
      })
    }

    this.setState({
      selectedOrders
    });
  };


  renderTrackingOrders = () => {
    const { workOrderList = [], loading } = this.props.transactionDetails;
    //const { workOrderList = [], loading } = Mock[0].serviceResponse;
    if (!loading) {
      return workOrderList.map(order =>
        this.renderTrackingOrderList(order)
      );
    }
  }

  renderTrackingOrderList = (order = {}) => {
    const { workOrderNumber, trackingOrderList = [] } = order;
    if (trackingOrderList.length) {
      const showUnSchedulableOrders = trackingOrderList.filter(order => !order.allowToSchedule).length > 0 ? true : false;
      return (
        <div style={{ marginBottom: '1rem' }}>
          <div>
            <div style={{ border: '1px solid #909090' }}>
              <div className='container-fluid' style={{ marginTop: '20px' }}>
                {(<h2 style={{ alignSelf: 'flex-start' }} className="t4 mar-top-16" role="link">Order Number<br role="presentation" />
                  <span style={{ color: '#0060A9' }}>
                    {workOrderNumber != null ? <a href='https://www.costco.com/OrderStatusCmd' target='_blank' style={{ textDecoration: 'none' }} aria-label={"Order Number: " + workOrderNumber}>{workOrderNumber}</a> : 'Not Available'}
                  </span>
                </h2>)}
                <div style={{ visibility: trackingOrderList[0].orderCreatedDate == null ? 'hidden' : 'visible' }}><span style={{ fontWeight: 'bold' }}>Order Date: </span>{trackingOrderList[0].orderCreatedDate}</div>
              </div>
              {trackingOrderList.map((order, index) => (
                <div>
                  {order.allowToSchedule && order.orderDeliveryStatus === "ND" && order.noSchCode === null && (
                    <div key={order.trackingNumber} className="container-fluid orderBody">
                      <div className="row">
                        <div className={"col " + (this.getCheckedValue(workOrderNumber, order.trackingNumber) ? "selectedRow" : "unselectedRow")} ref={(comp) => { this[`comp-${order.trackingNumber}`] = comp }} id={order.trackingNumber}>
                          <Stack
                            direction="row"
                            justifyContent="left"
                            alignItems="center"
                            spacing={{ xs: 0, sm: 2, md: 2, lg: 2 }}
                          >
                            <div>
                              {order.allowToSchedule && order.noSchCode === null &&
                                <input
                                  aria-label={'Tracking Number ' + order.trackingNumber}
                                  style={{ verticalAlign: 'bottom' }}
                                  className="largeCheckbox"
                                  type="checkbox"
                                  checked={this.getCheckedValue(workOrderNumber, order.trackingNumber)}
                                  onClick={(e) => this.handleSelection(e.target.checked, workOrderNumber, order.trackingNumber)}
                                />
                              }
                            </div>
                            <div>
                              <Stack
                                direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                                spacing={{ xs: 1, sm: 15, md: 25, lg: 40 }}
                                alignItems={{ xs: 'left', sm: 'left', md: 'right', lg: 'right' }}
                              >
                                <item style={{ wordBreak: 'break-word' }}>
                                  <span>Tracking Number&nbsp;</span><br role="presentation" />
                                  <span>{order.trackingNumber}</span>
                                </item>
                                <item id="deliveryDate">
                                  <span style={{ verticalAlign: 'middle' }}>Delivery or Return Pickup Date</span><br role="presentation" />
                                  <span style={moment.utc(order.latestShipDate).format('YYYY') === '1900' ? { fontWeight: 'bold', color: '#000' } : { fontWeight: 'bold', color: '#008000' }}>
                                    {moment.utc(order.latestShipDate).format('YYYY') === '1900' ? order.scheduleStatus : moment.utc(order.latestShipDate).format('LLLL').substring(0, moment.utc(order.latestShipDate).format('LLLL').length - 8)}
                                  </span>
                                </item>
                              </Stack>
                            </div>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {showUnSchedulableOrders && (
                <div className="container-fluid orderBody">
                  <hr aria-hidden="true" style={{ width: '100%', color: '#CCCCCC' }} />
                  <div class="row">
                    <div class="col d-inline-flex" style={{ backgroundColor: '#FFF8D2', border: '2px solid #FFCC00', borderRadius: '5px', minHeight: '60px' }}>
                      <span className="align-middle" style={{ padding: "10px" }}>The following tracking numbers for this order cannot be scheduled at this time.</span>
                    </div>
                  </div>
                  {trackingOrderList.map((order, index) => (
                    <div>
                      {(!order.allowToSchedule || order.orderDeliveryStatus === "DEL" || order.noSchCode != null) && (
                        <div key={order.trackingNumber} className="orderBody">
                          <div className="row">
                            <div className="col unselectedRow">
                              <Stack
                                direction="column"
                                justifyContent="left"
                                alignItems="left"
                                spacing={2}
                              >
                                <div>
                                  <Stack
                                    direction={{ xs: 'column', sm: 'row', md: 'row', lg: 'row' }}
                                    spacing={{ xs: 1, sm: 15, md: 25, lg: 40 }}
                                    alignItems="flex-start"
                                    justifyContent="space-between"
                                  >
                                    <item>
                                      <span>Tracking Number&nbsp;</span><br role="presentation" />
                                      <span>{order.trackingNumber}</span>
                                    </item>
                                    {order?.displayDateFlag === true && (
                                      <item id="deliveryDate">
                                        <span style={{ verticalAlign: 'middle' }}>Delivery or Return Pickup Date</span><br />
                                        <span style={moment.utc(order.latestShipDate).format('YYYY') === '1900' ? { fontWeight: 'bold', color: '#000' } : { fontWeight: 'bold', color: '#008000'}}>
                                          {moment.utc(order.latestShipDate).format('YYYY') === '1900' ? order.scheduleStatus : moment.utc(order.latestShipDate).format('LLLL').substring(0, moment.utc(order.latestShipDate).format('LLLL').length - 8)}
                                        </span>
                                      </item>
                                    )}
                                  </Stack>
                                </div>
                                <div>
                                  <item>
                                    <span style={{ fontWeight: 'bold' }}>Scheduling Status</span><br role="presentation" />
                                    <span>{parse(order.scheduleStatus)}</span>
                                  </item>
                                </div>
                              </Stack>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <br role="presentation" />
            </div>
          </div>
        </div>
      )
    } else {
      return '';
    }
  }

  getCheckedValue = (workOrderNumber, trackingOrderNumber) => {
    //verify if the check box checked status for the tracling number exists
    //If exists, update the selected orders lists passing the selected boolean to true
    //pass the value to the checkbox checked property
    if (sessionStorage.getItem(trackingOrderNumber) !== null) {
      const { selectedOrders } = this.state;
      selectedOrders.map(order => {
        if (order.workOrderNumber === workOrderNumber) {
          order.trackingOrderList.map(tracking => {
            if (tracking.trackingNumber === trackingOrderNumber) {
              tracking.selected = true;
            }
          })
        }
        return order;
      })
      return true;
    } else {
      const { selectedOrders } = this.state;
      const matchingWorkOrder = selectedOrders.find(order => order.workOrderNumber === workOrderNumber);
      if (matchingWorkOrder) {
        const { trackingOrderList = [] } = matchingWorkOrder;
        const workOrderList = trackingOrderList.find(order => order.trackingNumber === trackingOrderNumber) || {};
        return workOrderList.selected || false;
      }
    }
  }

  getSelectAllValue = () => {
    const { selectedOrders } = this.state;
    var allSelected = [];
    selectedOrders.forEach(order => {
      order.trackingOrderList.forEach(tracking => {
        if (tracking.allowToSchedule) {
          tracking.selected = sessionStorage.getItem(tracking.trackingNumber) ? true : tracking.selected;
          allSelected.push(tracking)
        }
      })
      //return order.trackingOrderList.some(status => status.allowToSchedule && status.selected === true)
    });

    return allSelected.every(status => status.allowToSchedule && status.selected === true);
  }

  render() {
    const messObj = this.props.messageObj;
    let showTrackingOrders = true;
    let scheduleOrderType = false;
    if (!this.state.loading && this.props.transactionDetails) {
      const { workOrderList = [], scheduleTransactionDetail = {} } = this.props.transactionDetails;
      scheduleOrderType = scheduleTransactionDetail?.scheduleOrderType;
      if (Array.isArray(workOrderList)) {
        const firstTrackingOrder = workOrderList[0] || {};
        const firstTrackingWorkorder = firstTrackingOrder.trackingOrderList || [];
        const firstWorkorder = Array.isArray(firstTrackingWorkorder) ? firstTrackingWorkorder[0] : {};
        const noSchCodes = firstTrackingWorkorder.map(order => order.noSchCode) || [];

        if (workOrderList.length === 1 && noSchCodes.length === 1 && firstWorkorder.noSchCode) {
          //showTrackingOrders = false;
          this.props.getMessageToShow({
            alertType: 'error',
            message: firstWorkorder.scheduleStatus
          });
        }
      } else {
        showTrackingOrders = false;
        this.props.getMessageToShow({
          alertType: 'error',
          message: parse(process.env.REACT_APP_MSC_CONTACT_US)
        });
      }
    }
    if (this.state.loading || this.props.transactionDetails.loading) {
      return (
        <div style={{ minHeight: '90vh', marginTop: '50vh' }} ref={this.topReference}>
          <div className="centerAlign" style={{ flexDirection: 'column' }}>
            <TrackingSpinner />
          </div>
        </div>
      )
    } else {
      return (
        <div className="container-fluid main-grid" style={{ minHeight: '90vh' }} ref={this.topReference} role="main">
          <LogoffLayer />
          <div>
            <div className="row">
              <div className='col-lg-10 offset-md-0 offset-lg-1'>
                <div id="mains" className="sr-only" tabIndex="-1"></div>
                <div id="banner" aria-labelledby='banner'>
                  {(this.state.loadRender || this.state.callError) &&
                    (<h1 className="t1" aria-label='Schedule My Delivery or Return Pickup.'>
                      Schedule My Delivery or Return Pickup
                    </h1>)}
                  {this.state.orderValid && !this.state.callError && this.state.loadRender && (
                    <div>
                      <p className="body-copy" style={{ marginTop: '8px' }}>Based on the information provided the following Costco.com orders were found.</p>
                      <p className="body-copy">To schedule (or reschedule) separate delivery or return pickup days select an individual tracking number.  To schedule all of your delivery or return pickup for the same day, Select All.</p>
                      <p className="body-copy">Not all deliveries or return pickups can be scheduled online.</p>
                      {' '}
                      <InnovelServiceAlert messObj={messObj} name='SCHEDULER' />
                    </div>
                  )}
                  {this.state.callError && (
                    <div>
                      {/*<p style={{ marginTop: '10px' }}>{this.state.errorMessage}</p>*/}
                      <div id="noSchedule" role="alert" tabIndex="-1" className="err-msg" style={{ float: "center", width: "100%", marginTop: '10px' }}>
                        <p align="center" style={{ textAlign: "center", marginTop: "16px" }}>
                          {this.state.errorMessage}
                        </p>
                      </div>

                      {' '}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className='col-xl-11 col-lg-11 col-sm-12 col-md-11 offset-md-0 offset-lg-1 traking-ele'>
                {this.state.showErrorMessage && !this.state.callError && (
                  <div id="noSchedule" role="alert" tabIndex="-1" className="err-msg" style={{ float: this.state.orderValid ? "left" : "center", width: "100%" }}>
                    <p align="center" style={{ textAlign: this.state.orderValid ? "left" : "center" }} className="">
                      {this.state.errorMessage}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {this.state.loadRender && <div className='col-lg-10 offset-md-0 offset-lg-1 result-ele'>
                {!this.state.callError && this.state.orderValid && this.state.ordersAvailable && <div>
                  <input
                    aria-label='Select All'
                    style={{ alignSelf: 'flex-start' }}
                    className="smallCheckbox"
                    type="checkbox"
                    checked={this.getSelectAllValue()}
                    onClick={(e) => this.handleSelectAll(e.target.checked)}
                  />
                  <span>Select All</span>
                  <hr aria-hidden="true" style={{ width: '100%', color: '#CCCCCC' }} />
                </div>}
                {!this.state.callError && showTrackingOrders && this.state.orderValid && this.renderTrackingOrders()}
              </div>}
            </div>
            <br role="presentation" />
            {this.state.orderValid && showTrackingOrders && !this.state.callError && this.state.loadRender && (
              <div className="col-lg-10 col-md-11 col-sm-11 col-xs-12 offset-sm-1 offset-md-1 offset-lg-1">
                <Stack
                  direction={{ xs: 'column-reverse', sm: 'row', md: 'row', lg: 'row' }}
                  spacing={{ xs: 2, sm: 2, md: 4, lg: 4 }}
                  justifyContent={{ xs: 'center', sm: 'right', md: 'right', lg: 'right' }}
                  alignItems={{ xs: 'center', sm: 'right', md: 'right', lg: 'right' }}
                  fullWidth={{ xs: 'true', sm: 'true' }}
                >
                  <Link
                    href="userselfschedule"
                    underline="hover"
                    sx={{ color: '#0060A9' }}
                  >
                    Back
                  </Link>
                  <Button
                    disableRipple
                    role="link"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: '#3071a9', minWidth: 260, maxWidth: 400, textTransform: 'capitalize', fontSize: "16px" }}
                    disabled={this.props.actionDisabled}
                    onClick={this.state.ordersAvailable && !this.state.disableBtn ? this.handleClick : () => this.props.history.push('/')}
                    btnText={this.state.ordersAvailable ? 'Select Date' : 'Cancel'}
                  />
                </Stack>
              </div>
            )}
          </div>
          <br role="presentation" />
          <br role="presentation" />
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    transactionDetails: state.transactionDetails,
    messageObj: state.maintenanceAlertMessage.maintenanceMessage,
    actionDisabled: state.maintenanceAlertMessage.disabled
  };
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindActionCreators(
      { validateTrackNbrForScheduling, getStarter, getMessageToShow, getOrderServiceMaintenanceAlert, fetchNewData, getUTCTime },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StartPage);
